<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" xl="10">
                <custom-page-title page-title="menu.help.company-registration.overview"></custom-page-title>

                <p>Az elektronikus cégeljárásban való részvételhez minősített
                    elektronikus aláírásra és időbélyegzőre van szükség. Az egyszerűsített
                    cégeljáráshoz szükséges szerződésmintákat a cégbejegyzési eljárás és a
                    cégnyilvántartás egyes kérdéseiről szóló 21/2006. (V. 18.) IM rendelet
                    mellékletei tartalmazzák, amelyek szerkeszthető formátumban a <a
                        href="https://ceginformaciosszolgalat.kormany.hu/szerzodesmintak" target="_blank">Céginformációs
                        Szolgálat</a> oldalán érhetőek el. A nyomtatványkitöltő szoftvereket forgalmazó
                    cégek listája
                    <router-link to="/help/download">ezen a linken</router-link>
                    érhető
                    el.
                </p>

                <p>Az alábbi leíráson kívül tekintse meg részletes, ábrákkal
                    illusztrált leírásainkat a
                    <router-link to="/help/software-guide">(változás)bejegyzés</router-link>
                    elkészítésének
                    folyamatáról, illetve az
                    <router-link to="/help/fee">elektronikus
                        fizetés és igazoláskérésről
                    </router-link>
                    .
                </p>

                <h2>A (változás)bejegyzési kérelem benyújtása</h2>

                <p>A hatályos Cégtörvény értelmében a jogi képviselő feladata
                    az elektronikus változásbejegyzési kérelem összeállítása és cégbíróságra
                    továbbítása. A kérelemben kétféle irat szerepelhet:</p>
                <ol>
                    <li><p><b>Eredetileg papír alapú okiratok
                        szkennelt formában, minősített elektronikus aláírással és időbélyegzővel
                        ellátva</b></p>

                        <p>Ebben az esetben a papír alapú cégiratok elektronikus
                            okirati formába való átalakítása szükséges. A jogi képviselő feladata a
                            bejegyzési kérelem mellékletét képező, nem általa készített okiratok (pl: tulajdoni lap, hatósági engedély) elektronikus okirati
                            formába történő átalakítása.<br>
                            Ez a gyakorlatban a papír alapú iratok szkennelését és (ha a törvény eltérően
                            nem rendelkezik) minősített elektronikus aláírással, valamint időbélyegzővel
                            való ellátását jelenti.</p>

                        <div>A szkennelésre vonatkozó műszaki követelmények:
                            <ul>

                                <li>A szkennelt képeket kizárólag abban az esetben fogadja el a
                                    cégbíróság, ha azon az eredeti papír alapú okirat szövege jól olvasható.
                                </li>

                                <li>A szkennelt kép felbontása 240-300 dpi
                                    közötti lehet.
                                </li>

                                <li>A szkennelt kép fekete-fehér legyen, tehát ne színes és ne
                                    szürkeskálás. (A színes aláírásokat tartalmazó oldalakat is lehet
                                    fekete-fehérben szkennelni.)
                                </li>

                                <li>A szkennelt dokumentum PDF formátumú lehet.</li>

                                <li>A szkennelt dokumentum lehetőleg ne tartalmazzon üres
                                    oldalakat, azaz kétoldalas szkennelésnél az üres oldalak elhagyandók.
                                </li>

                                <li>Szkennelés után ellenőrizze a szkennelt dokumentum méretét.
                                    A fentiek betartása esetén a fájlméretnek oldalanként 50-100 kB körül kell
                                    lennie. Ha a fájlméret oldalanként többszáz kB vagy 1 MB-nál is több, akkor
                                    ellenőrizze a szkenner beállításokat és szkennelje újra a dokumentumot.
                                </li>
                            </ul>
                        </div>
                        <p></p>

                        <p>A szkennelt dokumentum elnevezésének meg kell felelnie az
                            Igazságügyi Minisztérium által közzétett követelményeknek. A megfelelő
                            elnevezést az aláíró programban (e-Szignó vagy MOKKA) a dokumentum
                            beillesztésekor lehet kiválasztani (e-cégeljárás séma használatakor), tehát a
                            fájlrendszerben a dokumentumnak tetszőleges neve lehet.</p>

                        <p><b>A papír alapú cégiratok megőrzése és bemutatása</b></p>

                        <p>A jogi képviselő az eredetileg nem elektronikus formában
                            készült okiratok megőrzéséről az ügyvédekről szóló törvény, illetve a
                            közjegyzőkről szóló törvényben szabályozott módon gondoskodik. A jogi képviselő
                            köteles a papíralapú okiratot a cégbíróság felhívására bemutatni az
                            elektronikus okirattal való egyezőség megállapítása érdekében, ha e tekintetben
                            a cégbíróságnak kételye merül fel. A jogi képviselő tevékenységének
                            befejezésekor az ügyvédi/közjegyzői levéltár veszi át az őrzési és a bemutatási
                            feladatokat.</p>
                    </li>
                    <li>
                        <p><b>Eredetileg elektronikus formában
                            létrehozott okiratok, ha azokat az okirat aláírói minősített elektronikus
                            aláírással és időbélyegzővel látták el</b></p>

                        <p>Elektronikus okiratok is közvetlenül csatolhatóak az
                            elektronikus bejegyzési kérelemhez, ha azokat az aláírók minősített
                            elektronikus aláírással és időbélyegzővel látták el.</p>

                        <p>A csatolt okiratok formátuma sima szöveg (text), és PDF
                            lehet, vagy olyan ES3, illetve DOSSZIE kiterjesztésű elektronikus akta,
                            amelyben az előzőleg felsorolt formátumú iratok szerepelnek.</p>

                        <p>A Wordben szerkesztett dokumentumokat PDF formátumban
                            elmentve lehet csatolni.<br>
                            Amennyiben az okiratokat papíron is aláírják (pl. a cég tulajdonosai), akkor az
                            aláírt papír okiratot kell szkenneléssel PDF formátumú elektronikus okirattá
                            alakítani.</p>
                    </li>
                </ol>

                <p>A szkennelt okiratok és az elektronikus úton keletkezett
                    okiratok vegyesen is szerepelhetnek az elektronikus kérelemben.</p>

                <h2>A kérelem elküldése</h2>

                <p>A kérelmet, mely már tartalmazza a Magyar Államkincstár
                    által a befizetésről küldött elektronikus igazolást, ezen a Portálon keresztül van lehetőség megküldeni, vagy az illetékes
                    cégbíróság
                    <router-link to="/help/courts">e-mail címére</router-link>
                    csatolt fájlként kell elküldeni.
                </p>

                <p><b>A Céginformációs Szolgálat automatája kizárólag azokat az e-mail
                    leveleket fogadja be, amelynek csatolmányában e-akta található.</b></p>

                <h3>A kérelem útja</h3>

                <p>A bejegyzési kérelmek a Céginformációs Szolgálathoz érkeznek, a
                    benyújtásra vonatkozó határidők szempontjából a Céginformációs Szolgálathoz érkezés
                    időpontja irányadó. A Céginformációs Szolgálat az elektronikus okiratot informatikai
                    szempontból megvizsgálja, majd továbbítja az illetékes cégbírósághoz. Ha a
                    bejegyzési (változásbejegyzési) kérelem formanyomtatványa és mellékletei
                    informatikai vagy formai szempontból hibásak vagy hiányosak, nem tartalmazzák
                    megfelelően a Ctv. 44. §-ban foglalt adatközléshez
                    szükséges adatokat, továbbá, ha a Ctv. 38. § szerinti
                    adatigénylés eredményeként megállapítható, hogy a formanyomtatványban elírásnak
                    nem tekinthető érdemi hiba szerepel, a Céginformációs Szolgálat a kérelmet a
                    (3) bekezdés szerinti elektronikus igazolással együtt a jogi képviselőnek
                    visszaküldi. Ebben az esetben a bejegyzési (változásbejegyzési) kérelmet be nem
                    nyújtottnak kell tekinteni. Ha a bejegyzési (változásbejegyzési) kérelem
                    informatikai és formai szempontból szabályszerűen került benyújtásra és a
                    kérelem formanyomtatványában feltüntetett adatok ellenőrzésére is sikeresen sor
                    került, a Céginformációs Szolgálat haladéktalanul továbbítja azt az illetékes
                    cégbírósághoz. A Céginformációs Szolgálat tájékoztatja továbbá a cégbíróságot a
                    38. § szerinti adatigénylés eredményéről. Ha a Céginformációs Szolgálat az
                    adatok ellenőrzése során hibát észlelt, azonban a kérelem visszaküldésének
                    esete nem állt fent, megjelöli a hibásan feltüntetett adatot is. Az
                    informatikai szempontból értelmezhető emailek esetén
                    a Céginformációs Szolgálat egy elektronikus tértivevényt küld, ami tartalmazza az
                    informatikai vizsgálat eredményét. </p>

                <p>Kérjük, minden esetben tekintse meg az informatikai
                    vizsgálat eredményét, mert az informatikai szempontból hibás kérelmeket be nem
                    nyújtottnak kell tekinteni.</p>

                <p>Az elektronikus aktát nem tartalmazó emaileket
                    és az informatikai szempontból nem értelmezhető emaileket
                    a rendszer nem fogadja be (spamnek tekinti), és nem is küld visszajelzést.</p>

                <h3>A végzések megküldése</h3>

                <p>A cégbíróságra vonatkozó ügyintézési határidő az azt követő
                    első munkanapon kezdődik, amikor a Céginformációs Szolgálattól az informatikai szempontból
                    szabályszerű bejegyzési kérelem a cégbírósághoz megérkezik. A cégbíróság csak
                    az informatikai szempontból szabályszerűen beérkezett kérelem alapján ad ki
                    tanúsítványt. A kézbesítéshez fűződő jogkövetkezmények az elektronikus úton
                    történő közléshez fűződnek. Ha a cégbíróság 7 munkanapon belül nem kap
                    visszaigazolást a jogi képviselőtől, a végzést a cégbíróság írásban is
                    megküldi. Ebben az esetben a kézbesítéshez fűződő jogkövetkezmények az írásban
                    történő közléshez fűződnek.</p>

                <h3>Cégiratok benyújtása elektronikus úton, ha a törvény
                    személyt vagy szervezetet kötelez</h3>

                <p>Ha törvény a cégirat cégbírósághoz történő benyújtására nem
                    a céget, hanem közvetlenül valamely személyt vagy szervezetet kötelezi (pl. a
                    befolyással rendelkező bejelentése a befolyásszerzésről), a kötelezett e
                    kötelezettségét elektronikus úton is teljesítheti. Ebben az esetben a jogi
                    képviselet kötelező, az irat benyújtására a fentiek az irányadók.</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import CustomPageTitle from "@/components/CustomPageTitle";

@Component({
    components: {
        CustomPageTitle
    },

})
export default class HelpCompanyRegistrationOverview extends Vue {
}
</script>

<style scoped>

</style>